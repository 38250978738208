<template>
  <v-row class="fill-height white--text contactForm" justify="center">
    <v-col class="d-flex flex-column justify-center px-10" cols="12" md="5">
      <h1
        class="display-2 font-weight-bold"
        :class="$vuetify.breakpoint.smAndDown ? 'pt-10' : ''"
      >
        Subcontractor List
      </h1>
      <h2>Fill out the form to join our contractor list.</h2>
    </v-col>
    <v-col
      cols="12"
      md="7"
      lg="5"
      class="d-flex flex-column justify-center my-auto px-16"
      :class="$vuetify.breakpoint.smAndDown ? 'pb-16' : ''"
    >
      <v-form
        @submit.prevent="valid ? submit : validate"
        v-model="valid"
        ref="form"
      >
        <v-text-field
          v-model="fullName"
          label="Full Name *"
          name="data[fullName]"
          outlined
          :rules="nameRules"
          dark
        />
        <div
          class="d-flex"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Email *"
            name="data[email]"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'mr-2'"
            outlined
            dark
          />
          <v-text-field
            v-model="phone"
            label="Phone (optional)"
            name="data[phone]"
            type="tel"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-2'"
            outlined
            dark
          />
        </div>
        <v-text-field
          v-model="company"
          label="Company"
          name="data[company]"
          outlined
          dark
        />
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          :rules="divisionRules"
          v-model="division"
          label="Division *"
          name="data[division]"
          outlined
          dark
          :items="divisions"
        />
        <v-btn
          @click="validate"
          type="submit"
          color="primary"
          width="100%"
          outlined
          rounded
        >
          Submit
        </v-btn>
      </v-form>
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      fullName: "",
      email: "",
      phone: "",
      company: "",
      division: "",
      nameRules: [(v) => !!v || "Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      divisionRules: [(v) => !!v || "Division is required"],
      snackbar: false,
      snackbarText: "",
      divisions: [
        "1 - General Requirements",
        "2 - Existing Conditions",
        "3 - Concrete",
        "4 - Masonry",
        "5 - Metals",
        "6 - Woods and Plastics",
        "7 - Thermal and Moisture Protection",
        "8 - Openings Doors Windows and Glass",
        "9 - Finishes",
        "10 - Specialties",
        "11 - Equipment",
        "12 - Furnishings",
        "13 - Special Construction",
        "14 - Conveying Equipment",
        "21 - Fire Suppression",
        "22 - Plumbing",
        "23 - HVAC",
        "25 - Integrated Automation",
        "26 - Electrical",
        "27 - Communications",
        "28 - Electronic Safety and Security",
        "31 - Earthwork",
        "32 - Exterior Improvements",
        "33 - Utilities",
        "34 - Transportation",
      ],
    };
  },
  methods: {
    validate() {
      let result = this.$refs.form.validate();
      if (result) this.submit();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submit() {
      let data = document.querySelector("form");
      fetch("https://sheetdb.io/api/v1/9l7oza3hl3aez", {
        method: "POST",
        body: new FormData(data),
      })
        .then((response) => response.json())
        .then((e) => {
          this.snackbarText = e.created == 1 ? "Submitted" : "Failed to submit";
          this.snackbar = true;
          this.reset();
          this.resetValidation();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .Dq4amc {
  display: none !important;
}

.contactForm {
  min-height: 90vh;
}
</style>
